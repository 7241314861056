import React from "react";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const LastStep = (props) => {

  const card1 = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img src='/icons/icon6.png' style={{ margin: 10, width: 60 }} />
        <Typography variant="h5" sx={{ textAlign: "center" }}>
            Loan Amount
        </Typography>
        <Typography align="center" sx={{mt:1,fontStyle:'italic',fontWeight:"400"}}>up to</Typography>
        <Typography sx={{ mt: 0, textAlign: "center" }} variant="h4" color="#0D5BA6">
            ${new Intl.NumberFormat().format(props.value.loanAmountRequested)}
        </Typography>
    </CardContent>
</div>



);
const card2 = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img src='/icons/icon6.png' style={{ margin: 10, width: 60 }} />
        <Typography variant="h5" sx={{ textAlign: "center" }}>
            Interest Rate       
        </Typography>

        <Typography sx={{ mt: 1, textAlign: "center" }} variant="h4" color="#0D5BA6">
            10%
        </Typography>
        <Typography align="center"  sx={{fontStyle:'italic',fontWeight:"400"}} >to</Typography>
        <Typography sx={{ mt: 0, textAlign: "center" }} variant="h4" color="#0D5BA6">
            11.75%
        </Typography>
    </CardContent>
</div>

);
const card3 = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
    <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <img src='/icons/icon7.png' style={{ margin: 10, width: 60 }} />
        <Typography variant="h5" sx={{ textAlign: "center" }}>
            Origination Fee       
        </Typography>

        <Typography sx={{ mt: 1, textAlign: "center" }} variant="h4" color="#0D5BA6">
            1.0%
        </Typography>
        <Typography align="center"  sx={{fontStyle:'italic',fontWeight:"400"}}>to</Typography>
        <Typography sx={{ mt: 0, textAlign: "center" }} variant="h4" color="#0D5BA6">
            1.875%
        </Typography>
    </CardContent>
</div>

);
  return (
    <div>
            <Typography variant="h4" sx={{ justifyContent: "center",display: "flex", }}>
                <b>Congratulations</b>
            </Typography>
            <Typography variant="body2" align='center' sx={{fontWeight:"500",color:'rgb(0,0,0,0.5)',marginX:'15%',marginTop:"16px",}}>
                Based on the information you provided, you are pre qualified for a loan with New Silver. Here are the preliminary loan details:
            </Typography>
            <Grid  className='laststepcardsmainclass' >
                <Box  className='laststepcardsmainclassofbox'>
                    <Card className="cardsoflaststeps" style={{ flex: 1, margin: 5, borderRadius: 16,boxShadow:"0px 2px 0px #1976d2" }} variant="outlined">{card1}</Card>
                    <Card style={{ flex: 1, margin: 5, marginRight: 20 , marginLeft: 20, borderRadius: 16,boxShadow:"0px 2px 0px #1976d2" }} variant="outlined">{card2}</Card>
                    <Card style={{ flex: 1, margin: 5, borderRadius: 16,boxShadow:"0px 2px 0px #1976d2" }} variant="outlined">{card3}</Card>
                </Box>
            </Grid>
            <Typography variant="h5" sx={{ justifyContent: "center",display: "flex", }}>
                What's Next
            </Typography>
            <Typography variant="body2" align='center' sx={{fontWeight:"500",color:'rgb(0,0,0,0.5)',marginX:'15%',marginTop:"10px",}}>
                Give us upto 60 minutes to over your loan and we will reach out to you shortly so we can prepare a Term Sheet for you along with a proof of Funds letter.
            </Typography>
        </div>
  );
};
export default LastStep;
