import React from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import CircleIcon from "@mui/icons-material/Circle";
import CheckIcon from '@mui/icons-material/Check';

const MultiStepProgressBar = (props) => {
  return (
    <ProgressBar
      percent={(props.step + 0) * 25}
      filledBackground="#0D5BA6"
      height="5px"
      style={{ margin: "auto", border: "3px solid white" }}
    >
      <Step transition="scale">
        {({ accomplished, index }) => (
          <div
            style={{
              height: "25px",
              width: "25px",
              border: "3px solid white",
              borderRadius: "100%",
              backgroundColor: `${props?.step >= 0 ? "#0D5BA6" : "#E0E0E0"}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            className={`step ${props?.step >= 0 ? "completed" : null}`}
          >
            {props.step > 0 ? (
              <CheckIcon style={{ color: "#fff", width: 15, marginTop: 30, marginBottom: 10 }} className='classforcheckicon' />
            ) : (
              <div className='classforcheckicon' style={{ marginTop: 20, marginBottom: 10,  color: props.step >= 0 ? "#fff" : "#66676D" }}>
                01
              </div>
            )}
            <div
              style={{ fontSize: "14px", fontWeight: "500", marginTop: "5px", whiteSpace: "nowrap", color: props.step >= 0 ? "#0D5BA6" : "#424250" }}
            >  <div className="your-element">
                Loan & Experience
              </div>

            </div>
          </div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished, index }) => (
          <div
            style={{
              height: "25px",
              width: "25px",
              border: "3px solid white",
              borderRadius: "100%",
              backgroundColor: `${props?.step >= 1 ? "#0D5BA6" : "#E0E0E0"}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            className={`step ${props?.step >= 1 ? "completed" : null}`}
          >
            {props.step > 1 ? <CheckIcon className='classforcheckicon'  style={{ color: "#fff", width: 15, marginTop: 30, marginBottom: 10 }} /> : <div className='classforcheckicon' style={{ marginTop: 20, marginBottom: 10,  color: props.step >= 1 ? "#fff" : "#66676D" }}>02</div>}
            <div
              style={{ fontSize: "14px", fontWeight: "500", marginTop: "5px", whiteSpace: "nowrap", color: props.step >= 1 ? "#0D5BA6" : "#424250" }}
            > <div className="your-element">
                Property Address
          </div>

        
            </div>
          </div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished, index }) => (
          <div
            style={{
              height: "25px",
              width: "25px",
              border: "3px solid white",
              borderRadius: "100%",
              backgroundColor: `${props?.step >= 2 ? "#0D5BA6" : "#E0E0E0"}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            className={`step ${props?.step >= 2 ? "completed" : null}`}
          >
            {props.step > 2 ? <CheckIcon className='classforcheckicon' style={{ color: "#fff", width: 15, marginTop: 30, marginBottom: 10 }} /> : <div className='classforcheckicon' style={{ marginTop: 25, marginBottom: 10,  color: props.step >= 2 ? "#fff" : "#66676D" }}>03</div>}
            <div
              style={{ fontSize: "14px", fontWeight: "500", marginTop: "5px", whiteSpace: "nowrap", color: props.step >= 2 ? "#0D5BA6" : "#424250" }}
            >
         
              <div className="your-element">
              Personal Info
          </div>
            </div>
          </div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished, index }) => (
          <div
            style={{
              height: "25px",
              width: "25px",
              border: "3px solid white",
              borderRadius: "100%",
              backgroundColor: `${props?.step >= 3 ? "#0D5BA6" : "#E0E0E0"}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            className={`step ${props?.step >= 3 ? "completed" : null}`}
          >
            {props.step > 3 ? <CheckIcon className='classforcheckicon' style={{ color: "#fff", width: 15, marginTop: 30, marginBottom: 10 }} /> : <div className='classforcheckicon' style={{ marginTop: 20, marginBottom: 10, color: props.step >= 3 ? "#fff" : "#66676D" }}>04</div>}
            <div
              style={{ fontSize: "14px", fontWeight: "500", marginTop: "5px", whiteSpace: "nowrap", color: props.step >= 3 ? "#0D5BA6" : "#424250" }}
            >
              <div className="your-element">
              Property Info
          </div>
             
            </div>
          </div>
        )}
      </Step>
      <Step transition="scale">
        {({ accomplished, index }) => (
          <div
            style={{
              height: "25px",
              width: "25px",
              border: "3px solid white",
              borderRadius: "100%",
              backgroundColor: `${props?.step >= 4 ? "#0D5BA6" : "#E0E0E0"}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
            className={`step ${props?.step >= 4 ? "completed" : null}`}
          >
            {props.step > 4 ? <CheckIcon className='classforcheckicon' style={{ color: "#fff", width: 15, marginTop: 30, marginBottom: 10 }} /> : <div className='classforcheckicon' style={{ marginTop: 20, marginBottom: 10,  color: props.step >= 4 ? "#fff" : "#66676D" }}>05</div>}
            <div
              style={{ fontSize: "14px", fontWeight: "500", marginTop: "5px", whiteSpace: "nowrap", color: props.step >= 4 ? "#0D5BA6" : "#424250" }}
            >
                <div className="your-element">
                Loan Options
          </div>
             
            </div>
          </div>
        )}
      </Step>
    </ProgressBar>
  );
};

export default MultiStepProgressBar;
