import React, { Fragment, useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTheme } from '@mui/material/styles';


interface Props {
    // Define your component's props here
    nav: (type: any, value: any) => void;
}

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';

function loadAsyncScript(src) {
    return new Promise(resolve => {
        const script = document.createElement("script");
        Object.assign(script, {
            type: "text/javascript",
            async: true,
            src
        })
        script.addEventListener("load", () => resolve(script));
        document.head.appendChild(script);
    })
}

const FirstSection: React.FC<Props> = (props) => {

        
const cardBottomRef = useRef<HTMLDivElement>(null);
const handleApplyNowClick = () => {
    setShowNextSection(true);
    setTypeOfProject('Fix And Flip');
    

    window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    };

    const handleApplyNowClick2 = () => {
        setShowNextSection(true);
        setTypeOfProject('Ground Up');
        
    
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
          });
        };

    const [showNextSection, setShowNextSection] = useState(false);
    const [numOfProjects, setNumOfProjects] = useState(0);
    const [typeofProject, setTypeOfProject] = useState('');

    const initMapScript = () => {
        // if script already loaded
        if ((window as any).google) {
            return Promise.resolve();
        }
        const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
        return loadAsyncScript(src);
    }


    useEffect(() => {
        initMapScript().then(() => { console.log('initialized map script') });
    }, []);

    const buttonStyle = {
        backgroundColor: "#0D5BA6",
        color: "#fff",
        borderRadius: 25,
        paddingX: 4,
        paddingY: 1.2,
        mb: 1.5,
        ml: 1.5,
        fontWeight: "800",
        fontSize: "14px",
        '&:hover': {
            backgroundColor: "#0D5BA6",
        }
    };
    const imageStyle = {
        width: 90,
        margin: 10,
        marginBottom: 22,
    }

    const card1 = (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <CardContent>
                <img src='/icons/icon1.png' style={imageStyle} />
                <Typography variant="h5" sx={{ ml: 1, fontWeight: "600" }}>
                    Fix And Flip
                </Typography>
                <Typography sx={{ mt: 2, ml: 1, mr: 6 }} color="text.secondary" variant='subtitle1'>
                Funds for purchase & rehab for residential properties, rates from 10%
                </Typography>
            </CardContent>
            <CardActions>
                <Button sx={buttonStyle}  onClick={handleApplyNowClick}>Apply Now</Button>
            </CardActions>
        </div>
    );
    const card2 = (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <CardContent >
                <img src='/icons/icon2.png' style={imageStyle} />
                <Typography variant="h5" sx={{ ml: 1, fontWeight: "600" }}>
                Cash-Out Refinance
                </Typography>
                <Typography sx={{ mt: 2, ml: 1, mr: 6 }} color="text.secondary" variant='subtitle1'>
                Cash-out funds for properties you already own, rates from 10%
                </Typography>
            </CardContent>
            <CardActions >
                <Button sx={buttonStyle} onClick={() => {
                    props.nav("Rent", 0)
                }}>Apply Now</Button>
            </CardActions>
        </div>
    );
    const card3 = (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <CardContent >
                <img src='/icons/icon3.png' style={imageStyle} />
                <Typography variant="h5" sx={{ ml: 1, fontWeight: "600" }}>
                Bridge Loans
                </Typography>
                <Typography sx={{ mt: 2, ml: 1, mr: 6 }} color="text.secondary" variant='subtitle1'>
                Fast funds to help you secure the deal, rates form 10%
                </Typography>
            </CardContent>
            <CardActions >
                <Button sx={buttonStyle} onClick= {handleApplyNowClick2}>Apply Now</Button>
            </CardActions>
        </div>
    );
    const card4 = (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <CardContent >
                <img src='/icons/icon4.png' style={imageStyle} />
                <Typography variant="h5" sx={{ ml: 1, fontWeight: "600" }}>
                Ground Up
                </Typography>
                <Typography sx={{ mt: 2, ml: 1, mr: 4 }} color="text.secondary" variant='subtitle1'>
                Construction loans for builders and developers, rates from 10.5%
                </Typography>
            </CardContent>
            <CardActions >
                <Button sx={buttonStyle} onClick={() => {
                    props.nav("Personal", 0)
                }}>Apply Now</Button>
            </CardActions>
        </div>
    );
    const cardBottom = (
        <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', padding: '40px', gap: '20px' }}>
            <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h5" sx={{ color: "#0D5BA6", fontWeight: '400', textAlign: "center" }}>
                    {typeofProject === 'Fix And Flip'
                        ? "In the past 36 months, how many projects have you completed?"
                        : ""}
                </Typography>
                {typeofProject === 'Fix And Flip' && (
                    <Typography sx={{ mt: 1, fontSize: "25px", textAlign: "center" }} color="text.secondary">
                        This can include investment properties you own or flips you have started or finished.
                    </Typography>
                )}
                <div style={{ backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center", gap: '10px' }}>
                    <div style={{ display: "flex", flex: .1, justifyContent: "center", border: "2px solid", borderRadius: 50, borderColor: "#0D5BA6", padding: 8 }}>
                        <Button onClick={() => {
                            if (numOfProjects > 0) {
                                setNumOfProjects(numOfProjects - 1);
                            }
                        }} style={{ backgroundColor: "#E6EFF6", color: "#0D5BA6", borderRadius: '100%', height: '55px' }} aria-label="remove">
                            <RemoveIcon />
                        </Button>
                        <Typography sx={{ marginX: 5, color: "#0D5BA6", fontWeight: "500", fontSize: "40px" }}>{numOfProjects}</Typography>
                        <Button onClick={() => setNumOfProjects(numOfProjects + 1)} style={{ backgroundColor: "#E6EFF6", color: "#0D5BA6", borderRadius: '100%', height: '55px' }} aria-label="add">
                            <AddIcon />
                        </Button>
                    </div>
                    <Button
                        onClick={() => {
                            props.nav(typeofProject, numOfProjects);
                        }}
                        disabled={numOfProjects < 2 && typeofProject === 'Ground Up'}
                        sx={{
                            backgroundColor: "#0D5BA6",
                            color: "#fff",
                            borderRadius: '50px',
                            paddingY: '16px',
                            paddingX: '50px',
                            fontWeight: "800",
                            fontSize: "18px",
                            ':disabled': {
                                backgroundColor: "#E6EFF6",
                            },
                            '&:hover': {
                                backgroundColor: "#0D5BA6",
                            }
                        }}>Next</Button>
                </div>
                {numOfProjects < 2 && typeofProject === 'Ground Up' && (
                    <Typography variant="subtitle1" sx={{ color: "#d32f2f", textAlign: "center" }}>
                        At least 2 ground up projects in the past 36 months are required
                    </Typography>
                )}
            </CardContent>
        </Grid>

    );
    const theme = useTheme();
    const cardStyle = {
        flex: 1,
        margin: 3,
        borderRadius: 10,
        boxShadow: "rgb(0 0 0 / 0.1) 1px 4px 0px 0px",
        marginRight: 5,
        marginLeft: 5,
        paddingBottom: 10,
        paddingTop: 10,
        marginTop: 14
        // paddingLeft: 15
    };
    const cardStyleSelected = {
        flex: 1,
        margin: 3,
        borderRadius: 10,
        boxShadow: "rgb(12 92 166 / 71%) 0px 4px 0px 0px",
        marginRight: 5,
        marginLeft: 5,
        paddingBottom: 10,
        paddingTop: 10,
        marginTop: 14
        // paddingLeft: 15
    };


    return (
        <Grid container justifyContent="center" style={{ marginTop: 90 }}>
            <Grid item xs={12}>
                <Typography align='center'
                    sx={{
                        [theme.breakpoints.up('sm')]: {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',

                        },
                    }}
                >
                    <Typography variant="h2" style={{ color: "#0f1049", fontWeight: '500', wordSpacing: '5px', marginRight: '14px',marginLeft:'14px' }}>Select your Loan</Typography>
                    <Typography variant="h2" style={{ color: "#0f1049", fontWeight: '300', wordSpacing: '5px' }}>type below</Typography>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" sx={{ color: "gray", marginTop: '5px' }} align='center'>
                    Instant online approval. This application will not affect your credit score.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, flex: 0.8, padding: 4 }}>
                        <Card style={cardStyleSelected} variant="outlined" ref={cardBottomRef}>{card1}</Card>
                        <Card style={cardStyle} variant="outlined">{card2}</Card>
                        <Card style={cardStyle} variant="outlined">{card3}</Card>
                        <Card style={cardStyle} variant="outlined">{card4}</Card>
                    </Box>
                </Grid>
            </Grid>
            {showNextSection && (
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, flex: 0.72, paddingBottom: 4 }}>
                            <Card style={{ flex: 1, margin: 5, borderRadius: 15, boxShadow: "rgb(12 92 166 / 71%) 0px 4px 0px 0px" }} variant="outlined">{cardBottom}</Card>
                        </Box>
                    </Grid>
                </Grid>
                
            )}
              <Grid >
                    <Grid container justifyContent="center" alignItems="center">
                        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, flex: 0.72, paddingBottom: 4 }}>
                            <Box style={{ flex: 1, margin: 5, borderRadius: 15,  }} >&nbsp; <br/> &nbsp;<br/> &nbsp;
                            <br/> &nbsp; <br/> &nbsp; <br/> &nbsp;<br/> &nbsp;<br/> &nbsp;<br/> &nbsp;<br/> &nbsp;<br/> &nbsp;<br/> &nbsp;</Box>
                        </Box>
                    </Grid>
                </Grid>
        </Grid>

    );
};

export default FirstSection;
