import React, { useState } from "react";
import styles from './Styles.module.css'
import { MenuItem, Select, Slider, TextField, Typography } from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel, { inputLabelClasses } from "@mui/material/InputLabel";

const ThirdStep = (props) => {


  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    education: '',
    bankruptcy: '',
    creditScore: '',
  })
  console.log("Step 3 checking data: ", props.value)

  const validateForm = () => {
    const newErrors: any = {};
    if (props.value.firstName.trim() === '') {
      newErrors.firstName = 'First Name is required';
    } else {
      newErrors.firstName = '';
    }
    if (props.value.lastName.trim() === '') {
      newErrors.lastName = 'Last Name is required';
    } else {
      newErrors.lastName = '';
    }
    if (props.value.email.trim() === '') {
      newErrors.email = 'Email is required';
    } else {
      newErrors.email = '';
    }
    if (props.value.phone.trim() === '') {
      newErrors.phone = 'Phone Number is required';
    } else {
      newErrors.phone = '';
    }
    if (props.value.bankruptcy === "yes") {
      newErrors.bankruptcy = 'We are not able to finance borrowers with recent bankruptcies.';
    } else {
      newErrors.bankruptcy = '';
    }
    if (props.value.creditScore < 650) {
      newErrors.creditScore = 'Minimum credit score of 650 is required';
    } else {
      newErrors.creditScore = '';
    }

    // Check if there are any errors
    const hasErrors = Object.values(newErrors).some((error) => error !== "");
    if (hasErrors) {
      setErrors(newErrors);
      return false;
    }

    // If no errors, proceed to the next step
    return true;
  }

  const handleNext = () => {
    const isFormValid = validateForm();
    if (isFormValid) {
      props.nextStep();
    }
  }

  return (
    <div className="form">
      <div className='textfieldsclassmain'>
        <div className='divclassoftextfields'>
          <TextField
          fullWidth
            error={Boolean(errors.firstName)}
            id="outlined-required"
            label="Enter Your First Name &nbsp; &nbsp; &nbsp; &nbsp;"
            value={props.value.firstName}
            onChange={props.handleChange("firstName")}
            placeholder="Enter Your First Name"
            helperText={errors.firstName}
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#0F5BA6",
                  fontWeight: "500",
                }
              }
            }}
          />
        </div>

        <div className='divclassoftextfields2'>
          <TextField
          fullWidth
            error={Boolean(errors.lastName)}
            id="outlined-required"
            label="Enter Your Last Name &nbsp; &nbsp; &nbsp; &nbsp;"
            value={props.value.lastName}
            onChange={props.handleChange("lastName")}
            placeholder="Enter Your Last Name"
            helperText={errors.lastName}
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#0F5BA6",
                  fontWeight: "500",
                }
              }
            }}
          />
        </div>

        <div className='divclassoftextfields2'>
          <TextField
          fullWidth
            id="outlined-required"
            label="Enter Your Company Name &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
            value={props.value.companyName}
            onChange={props.handleChange("companyName")}
            placeholder="Enter Your Company Name"
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#0F5BA6",
                  fontWeight: "500",
                }
              }
            }}
          />
        </div>

      </div>
      <div className='textfieldsclassmain'>
        <div className='divclassoftextfields'>
          <TextField
          fullWidth
            error={Boolean(errors.email)}
            id="outlined-required"
            label="Enter Your Email &nbsp; &nbsp; &nbsp;"
            value={props.value.email}
            onChange={props.handleChange("email")}
            placeholder="Enter Your Email"
            helperText={errors.email}
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#0F5BA6",
                  fontWeight: "500",
                }
              }
            }}
          />
        </div>

        <div className='divclassoftextfields2'>
          <TextField
          fullWidth
            error={Boolean(errors.phone)}
            id="outlined-required"
            label="Enter Your Phone &nbsp; &nbsp; &nbsp;"
            value={props.value.phone}
            onChange={props.handleChange("phone")}
            placeholder="Enter Your Phone"
            helperText={errors.phone}
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#0F5BA6",
                  fontWeight: "500",
                }
              }
            }}
          />
        </div>

        <div className='divclassoftextfields2' style={{ display: 'flex' }}>
          <FormControl >
            <InputLabel id="demo-simple-select-helper-label"
              sx={{
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#0F5BA6",
                  fontWeight: "500",
                }
              }}
            >
              Enter Highest level of Education</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              label="Enter Highest level of Education &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; "
              value={props.value.education}
              onChange={props.handleChange("education")}
            >

              <MenuItem key={"bachelors"} value={"bachelors"}>
                Bachelor degree
              </MenuItem>
              <MenuItem key={"masters"} value={"masters"}>
                Master degree
              </MenuItem>
              <MenuItem key={"postgrad"} value={"postgrad"}>
                Post Grad degree
              </MenuItem>

            </Select>
          </FormControl>
        </div>

      </div>

      <div className={styles.sliderDiv}>
        <div style={{ marginBottom: "20px" }}>
          <Typography variant="h6" sx={{ fontWeight: '400', fontSize: "18px", }}>
            Enter Your Credit Score:
          </Typography>
        </div>
        <Slider
          name="creditScore"
          defaultValue={650}
          aria-label="Default"
          valueLabelDisplay="on"
          max={900}
          min={500}
          step={10}
          value={props.value.creditScore}
          onChange={props.handleChange("creditScore")}
          marks
          valueLabelFormat={(value) => <div style={{ color: '#fff', fontWeight: '500', fontSize: '16px' }}>{value}</div>}
          sx={
            {
              color: '#0D5BA6',
              '& .MuiSlider-thumb': {
                color: '#0D5BA6',
                borderWidth: '4px',
                borderColor: 'white',
                borderStyle: 'solid',
                width: '25px',     // Adjust the width as needed
                height: '25px',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.2)',
              },
              '& .MuiSlider-valueLabel': {
                color: 'white',
                backgroundColor: '#000',
                height: '16px',
              },
              '& .MuiSlider-mark': {
                height: '9%',
                width: '1px',
              },

            }
          }
        />
        {errors.creditScore && (
          <Typography variant="caption" sx={{ color: "#d32f2f" }}>{errors.creditScore}</Typography>
        )}
      </div>

      <div className={styles.booleanDiv}>
        <label>
          Do you have an active bankruptcy or foreclosure, or have you had one discharged in the past 24 months?
        </label>
        <FormControl>
          <RadioGroup
            row
            defaultValue="no"
            name="bankruptcy"
            value={props.value.bankruptcy}
            onChange={props.handleChange("bankruptcy")}
          >
            <FormControlLabel value="yes" control={<Radio size="small" />} label="YES" />
            <FormControlLabel value="no" control={<Radio size="small" />} label="NO" />
          </RadioGroup>
        </FormControl>
        {errors.bankruptcy && (
          <Typography variant="caption" sx={{ color: "#d32f2f" }}>{errors.bankruptcy}</Typography>
        )}
      </div>

      <div className={styles.booleanDiv}>
        <label>
          Are you or your spouse currently involved in any criminal investigation?
        </label>
        <FormControl>
          <RadioGroup
            row
            defaultValue="no"
            value={props.value.criminalInvestigation}
            name="criminalInvestigation"
            onChange={props.handleChange("criminalInvestigation")}
          >
            <FormControlLabel value="yes" control={<Radio size="small" />} label="YES" />
            <FormControlLabel value="no" control={<Radio size="small" />} label="NO" />
          </RadioGroup>
        </FormControl>

      </div>

      <div className="userForm-container-footer">
        {<button
          style={{ backgroundColor: "#F2F3F7", color: 'black', }}
          onClick={() => {
            props.prevStep();
          }}
        >
          BACK
        </button>
        }
        {<button onClick={handleNext}>
          NEXT
        </button>}
      </div>

    </div>
  );
};
export default ThirdStep;
