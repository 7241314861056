import React from 'react';
import Cards from '../Components/FirstSection';
import { useNavigate } from 'react-router-dom';



function Home() {
  const history = useNavigate();
  const redirectToForm = (type,value)=>{
    history(`/form`,{state:{value:value}});
  }

  return (
    <div style={{ marginTop: "20px", paddingBottom: "80px" }}>
      <div style={{ width: "100%" }}>
        <Cards nav={redirectToForm} />
      </div>
    </div>
  );
}

export default Home;