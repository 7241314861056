import React, { useState } from "react";
import styles from "./Styles.module.css";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Box, Fab, TextField } from "@mui/material";
import { inputLabelClasses } from "@mui/material/InputLabel";
const FourthStep = (props) => {

  const [errors,setErrors] = useState({
    purchasePrice: "",
    asIsVale: "",
    rehabBudget: "",
    loanAmountRequested: "",
    afterRepairValue: "",
    
  })

  const validateForm = () => {
    const newErrors:any = {};
    
    if(props.value.purchasePrice < 117700  || props.value.purchasePrice === ""){
      newErrors.purchasePrice = 'Minimum purchase price needs to be at least $117,700';
    }else{
      newErrors.purchasePrice = '';
    }
    if(props.value.asIsVale <= 117700 || props.value.asIsVale === ""){
      newErrors.asIsVale = 'Minimum As-Is Value needs to be at least $117,700';
    }else{
      newErrors.asIsVale = '';
    }
    if(props.value.rehabBudget < 0 || props.value.rehabBudget === ""){
      newErrors.rehabBudget = 'Rehab budget is required';
    }else{
      newErrors.rehabBudget = '';
    }
    if(props.value.loanAmountRequested <= 100000 || props.value.loanAmountRequested > ((props.value.asIsVale/100)*85) || props.value.loanAmountRequested === ""){
      newErrors.loanAmountRequested = 'Loan amount must be greater then $100,000 and upto 85% of As-Is Value';
    }else{
      newErrors.loanAmountRequested = '';
    }
    if(props.value.afterRepairValue < (props.value.asIsVale + props.value.rehabBudget) || props.value.afterRepairValue === ""){
      newErrors.afterRepairValue = 'After Repair Value must be at least As-Is Value + Rehab Budget. Please adjust those values.';
    }

    const hasErrors = Object.values(newErrors).some((error) => error !== "");
    if (hasErrors) {
      setErrors(newErrors);
      return false;
    }

    // If no errors, proceed to the next step
    return true;
      
    }
  

  const handleNext = () => {
    const isFormValid = validateForm();
      if (isFormValid) {
        props.nextStep();
      }
  }

  const FabStyle1 = {
    width: 20,
    height: 20,
    color: "#0D5BA6",
    flex: 1,
    fontWeight: "500",
    fontSize: "18px",
    backgroundColor: "#F2F4FB",
    borderRadius: 100,
    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
    margin: 5
  };

  const FabStyle2 = {
    width: 20,
    height: 20,
    backgroundColor: "#0D5BA6",
    color: "#fff",
    borderRadius: 100,
    flex: 1,
    fontWeight: "500",
    fontSize: "18px",
    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
    '&:hover': {
      backgroundColor: "#0D5BA6",
      color: "#fff",
      boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
    }
  };
  return (
    <div className="form">

      <div className={styles.step4SecondDiv}>

        <div className={styles.step4InnerDiv}>
          <label>Type of Transaction</label>
          <br />
          <FormControl>
            <RadioGroup
              defaultValue="New Purchase"
              value={props.value.transcationType}
              name="transcationType"
              onChange={props.handleChange("transcationType")}
            >
              <FormControlLabel value="New Purchase" control={<Radio size="small" />} label="New Purchase" />
              <FormControlLabel value="Refinance" control={<Radio size="small" />} label="Refinance" />
            </RadioGroup>
          </FormControl>

        </div>

        <div className={styles.step4InnerDiv}>
          <label>Property Use</label>
          <br />
          <FormControl>
            <RadioGroup
              value={props.value.propertyUse}
              defaultValue="Residential"
              name="propertyUse"
              onChange={props.handleChange("propertyUse")}
            >
              <FormControlLabel value="Residential" control={<Radio size="small" />} label="Residential" />
              <FormControlLabel value="Commercial" control={<Radio size="small" />} label="Commercial" />
              <FormControlLabel value="Mixed Use" control={<Radio size="small" />} label="Mixed Use" />
            </RadioGroup>
          </FormControl>

        </div>

        <div className={styles.step4InnerDiv}>
          <label>Type of Property</label>
          <br />
          <FormControl>
            <RadioGroup
              value={props.value.propertyType}
              defaultValue="Single Family"
              name="propertyType"
              onChange={props.handleChange("propertyType")}
            >
              <FormControlLabel value="Single Family" control={<Radio size="small" />} label="Single Family 1-4 Units" />
              <FormControlLabel value="Multi Family" control={<Radio size="small" />} label="Multi Family 5+ Units" />
            </RadioGroup>
          </FormControl>

        </div>

        <div className={styles.step4InnerDiv}>
          <label>Number of Units</label>
          <br />
          <div style={{ width: "90%" }}>
            <Box sx={{ '& > :not(style)': { m: 0.5 }, border: "1px solid #0D5BA6", borderRadius: "30px", display: "flex", flexDirection: "row", paddingTop: "4px", paddingBottom: "4px", paddingLeft: "10px", paddingRight: "10px", marginTop: "10px" }}>
              <Fab sx={props.value.numberOfUnits == 1 ? FabStyle2 : FabStyle1} onClick={() => { props.handleClick(1) }}>
                1
              </Fab>
              <Fab sx={props.value.numberOfUnits == 2 ? FabStyle2 : FabStyle1} onClick={() => { props.handleClick(2) }}>
                2
              </Fab>
              <Fab sx={props.value.numberOfUnits == 3 ? FabStyle2 : FabStyle1} onClick={() => { props.handleClick(3) }}>
                3
              </Fab>
              <Fab sx={props.value.numberOfUnits == 4 ? FabStyle2 : FabStyle1} onClick={() => { props.handleClick(4) }}>
                4
              </Fab>
            </Box>
          </div>
        </div>

      </div>

      <div  className='textfieldsclassmain'>
        <div  className='divclassoftextfields'>
          <TextField
            id="outlined-required"
            label="Enter Purchase Price &nbsp; &nbsp; &nbsp; &nbsp; "
            value={props.value.purchasePrice}
            onChange={props.handleChange("purchasePrice")}
            placeholder="Enter Purchase Price"
            type="number"
            error={Boolean(errors.purchasePrice)}
            helperText={errors.purchasePrice}
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#0F5BA6",
                  fontWeight: "500",
                }
              }
            }}
          />
        </div>

        <div  className='divclassoftextfields2'>
          <TextField
            id="outlined-required"
            label="Enter As Is Value &nbsp; &nbsp; &nbsp; "
            value={props.value.asIsVale}
            onChange={props.handleChange("asIsVale")}
            placeholder="Enter As Is Value"
            type="number"
            error={Boolean(errors.asIsVale)}
            helperText={errors.asIsVale}
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#0F5BA6",
                  fontWeight: "500",
                }
              }
            }}
          />
        </div>

        <div  className='divclassoftextfields2'>
          <TextField
            id="outlined-required"
            label="Enter Rehab Budget &nbsp; &nbsp; &nbsp; &nbsp;"
            value={props.value.rehabBudget}
            onChange={props.handleChange("rehabBudget")}
            placeholder="Enter Rehab Budget"
            type="number"
            error={Boolean(errors.rehabBudget)}
            helperText={errors.rehabBudget}
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#0F5BA6",
                  fontWeight: "500",
                }
              }
            }}
          />
        </div>

      </div>
      <div className='textfieldsclassmain'>
        <div  className='divclassoftextfields'>
          <TextField
            id="outlined-required"
            label="Enter Loan Amount Requested &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
            value={props.value.loanAmountRequested}
            onChange={props.handleChange("loanAmountRequested")}
            placeholder="Enter Loan Amount Requested"
            type="number"
            error={Boolean(errors.loanAmountRequested)}
            helperText={errors.loanAmountRequested}
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#0F5BA6",
                  fontWeight: "500",
                }
              }
            }}
          />
        </div>

        <div  className='divclassoftextfields2'>
          <TextField
            id="outlined-required"
            label="Enter After Repair Value &nbsp; &nbsp; &nbsp; &nbsp;"
            value={props.value.afterRepairValue}
            onChange={props.handleChange("afterRepairValue")}
            placeholder="Enter After Repair Value"
            type="number"
            error={Boolean(errors.afterRepairValue)}
            helperText={errors.afterRepairValue}
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#0F5BA6",
                  fontWeight: "500",
                }
              }
            }}
          />
        </div>

      </div>

      <div className="userForm-container-footer">
        {<button
          style={{ backgroundColor: "#F2F3F7", color: 'black', }}
          onClick={() => {
            props.prevStep();
          }}
        >
          BACK
        </button>
        }
        {<button onClick={handleNext}>
          NEXT
        </button>}
      </div>

    </div>
  );
};
export default FourthStep;
