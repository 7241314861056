//tsx boiler plate
import React, { useCallback, useState } from 'react';
import UserForm from '../Components/UserForm';
import {useLocation} from 'react-router-dom';

function LoanForm() {
    const location = useLocation();
    console.log('inside form screen', location.state.value);

    const [showForm, setShowForm] = useState(false);

    const changeVisibility = () => {
        setShowForm(!showForm);
    }
   
    return (
        <div style={{marginTop:"20px",paddingBottom:"80px", paddingTop:"80px" }}>
            <UserForm  initialStep={1}/>
        </div>
    );
}

export default LoanForm;