import { Checkbox, Select, TextField, Typography, MenuItem, InputLabel, FormControl } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { inputLabelClasses } from "@mui/material/InputLabel";

import { usStates } from "../usStates";

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';

// load google map api js

function loadAsyncScript(src) {
  return new Promise(resolve => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src
    })
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  })
}

const SecondStep = (props) => {

  const searchInput = useRef(null);
  const [address, setAddress] = useState({});

  const extractAddress = (place) => {

    const address = {
      city: "",
      state: "",
      zip: "",
      country: "",
      streetName: "",
      streetNumber: "",
      plain() {
        const streetNumber = this.streetNumber ? this.streetNumber + " " : "";
        const street = this.streetName ? this.streetName + ", " : "";
        const city = this.city ? this.city + ", " : "";
        const zip = this.zip ? this.zip + "" : "";
        const state = this.state ? this.state + ", " : "";
        return streetNumber + street + city + state + zip;
      }
    }
    if (!Array.isArray(place?.address_components)) {
      return address;
    }

    place.address_components.forEach(component => {
      const types = component.types;
      const value = component.long_name;

      if (types.includes("route")) {
        address.streetName = value;
      }
      if (types.includes("street_number")) {
        address.streetNumber = value;
      }

      if (types.includes("locality")) {
        address.city = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.state = value;
      }

      if (types.includes("postal_code")) {
        address.zip = value;
      }

      if (types.includes("country")) {
        address.country = value;
      }

    });
    //will call handle location here
    props.handleLocation(address);
    return address;
  }

  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if ((window as any).google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  }
  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    setAddress(extractAddress(place));
  }
  const initAutocomplete = () => {
    if (!searchInput.current) return;

    const autocomplete = new (window as any).google.maps.places.Autocomplete(searchInput.current, {
      componentRestrictions: { country: "us" },
    });
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));

  }


  // load map script after mounted
  useEffect(() => {
      initMapScript().then(() => initAutocomplete())
  }, []);


  const [errors, setErrors] = useState({
    propertyAddress: "",
    streetNumber: "",
    streetName: "",
    appartmentNumber: "",
    citySubrub: "",
    state: "",
    zipCode: "",
  });

  const validateForm = () => {
    const newErrors: any = {};

    // Validate each field only if the "I don't have a property" checkbox is not checked
    if (!props.value.checkboxValue) {
      if (props.value.propertyAddress.trim() === "") {
        newErrors.propertyAddress = "Property address is required";
      } else {
        newErrors.propertyAddress = "";
      }
      if (props.value.streetNumber.trim() === "") {
        newErrors.streetNumber = "Street number is required";
      } else {
        newErrors.streetNumber = "";
      }
      if (props.value.streetName.trim() === "") {
        newErrors.streetName = "Street name is required";
      } else {
        newErrors.streetName = "";
      }
      if (props.value.citySubrub.trim() === "") {
        newErrors.citySubrub = "City/Subrub is required";
      } else {
        newErrors.citySubrub = "";
      }
      if (props.value.state.trim() === "") {
        newErrors.state = "State is required";
      } else {
        newErrors.state = "";
      }
      if (props.value.zipCode.trim() === "") {
        newErrors.zipCode = "Zip code is required";
      } else {
        newErrors.zipCode = "";
      }

      // Add more validation logic for other fields
    }

    // Check if there are any errors
    const hasErrors = Object.values(newErrors).some((error) => error !== "");
    if (hasErrors) {
      setErrors(newErrors);
      return false;
    }

    // If no errors, proceed to the next step
    return true;
  };

  const handleCheckboxChange = (event) => {
    // Disable all fields and clear errors when the checkbox is checked
    if (event.target.checked) {
      setErrors({
        propertyAddress: "",
        streetNumber: "",
        streetName: "",
        appartmentNumber: "",
        citySubrub: "",
        state: "",
        zipCode: "",
      });
    }
    props.handleCheckbox("checkboxValue", !props.value.checkboxValue);
  };


  const handleNext = () => {
    // If the "I don't have a property" checkbox is checked, skip validation
    if (props.value.checkboxValue) {
      props.nextStep();
    } else {
      const isFormValid = validateForm();
      if (isFormValid) {
        props.nextStep();
      }
    }
  };


  return (
    <div className="form">
      <TextField
        inputRef={searchInput}
        label="Enter Your Property Address &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
        variant="outlined"
        value={props.value.propertyAddress}
        onChange={props.handleChange("propertyAddress")}
        disabled={props.value.checkboxValue}
        InputLabelProps={{
          sx: {
            [`&.${inputLabelClasses.shrink}`]: {
              color: "#0F5BA6",
              fontWeight: "500",
            }
          }
        }}
      />
      <Typography variant="body2" color="black" sx={{ marginTop: "8px", marginBottom: "10px", fontStyle: "italic", fontWeight: "light", }}>
        Please enter a valid US address
      </Typography>

      <div>
        <Checkbox checked={props.value.checkboxValue} onChange={handleCheckboxChange} />
        <span> I don't have a property address and I want to see what loan options are available</span>
      </div>

      <div className='textfieldsclassmain' >
        <div className='divclassoftextfields' >
          <TextField
          fullWidth 
            error={Boolean(errors.streetNumber)}
            id="outlined-required"
            label="Street Number &nbsp; &nbsp; &nbsp;"
            value={props.value.streetNumber}
            onChange={props.handleChange("streetNumber")}
            placeholder="Enter Street Number"
            helperText={errors.streetNumber}
            disabled={props.value.checkboxValue}
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#0F5BA6",
                  fontWeight: "500",
                }
              }
            }}
          />
        </div>

        <div className='divclassoftextfields2'>
          <TextField
          fullWidth 
            error={Boolean(errors.streetName)}
            id="outlined-required"
            label="Street Name &nbsp; &nbsp;"
            value={props.value.streetName}
            onChange={props.handleChange("streetName")}
            placeholder="Enter Street Name"
            helperText={errors.streetName}
            disabled={props.value.checkboxValue}
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#0F5BA6",
                  fontWeight: "500",
                }
              }
            }}
          />
        </div>

        <div className='divclassoftextfields2'>
          <TextField
          fullWidth 
            id="outlined-required"
            label="Appartment/Unit Number &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
            value={props.value.appartmentNumber}
            onChange={props.handleChange("appartmentNumber")}
            placeholder="Enter Appartment/Unit Number"
            disabled={props.value.checkboxValue}
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#0F5BA6",
                  fontWeight: "500",
                }
              }
            }}
          />
        </div>

      </div>

      <div className='textfieldsclassmain'>
        <div className='divclassoftextfields'>
          <TextField
          fullWidth
            error={Boolean(errors.citySubrub)}
            id="outlined-required"
            label="City/Suburb &nbsp; &nbsp;"
            value={props.value.citySubrub}
            onChange={props.handleChange("citySubrub")}
            placeholder="Enter City Name"
            helperText={errors.citySubrub}
            disabled={props.value.checkboxValue}
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#0F5BA6",
                  fontWeight: "500",
                }
              }
            }}
          />
        </div>

        <div className='divclassoftextfields2' style={{ display: 'flex' }} >
  <FormControl>
    <InputLabel
      id="demo-simple-select-helper-label"
      sx={{
        [`&.${inputLabelClasses.shrink}`]: {
          color: "#0F5BA6",
          fontWeight: "500",
          
        }
      }}
    >
      State
    </InputLabel>
    <Select
      error={Boolean(errors.state)}
      labelId="demo-simple-select-helper-label"
      id="demo-simple-select-helper"
      label="State &nbsp; "
      value={props.value.state}
      onChange={props.handleChange("state")}
      disabled={props.value.checkboxValue}
      sx={{ width: '100%' }} // Set width to 100%
    >
      {usStates.map((state) => {
        return (
          <MenuItem key={state} value={state}>
            {state}
          </MenuItem>
        );
      })}
    </Select>
  </FormControl>
</div>


        <div className='divclassoftextfields2'>
          <TextField
          fullWidth
            error={Boolean(errors.zipCode)}
            id="outlined-required"
            label="Zip/Postal Code &nbsp; &nbsp; &nbsp;"
            value={props.value.zipCode}
            onChange={props.handleChange("zipCode")}
            placeholder="Enter Zip Code"
            type="number"
            helperText={errors.zipCode}
            disabled={props.value.checkboxValue}
            InputLabelProps={{
              sx: {
                [`&.${inputLabelClasses.shrink}`]: {
                  color: "#0F5BA6",
                  fontWeight: "500",
                }
              }
            }}
          />
        </div>

      </div>

      <div className="userForm-container-footer">
        {<button
          style={{ backgroundColor: "#F2F3F7", color: 'black', }}
          onClick={() => {
            props.prevStep();
          }}
        >
          BACK
        </button>
        }
        {<button onClick={handleNext}>
          NEXT
        </button>}
      </div>

    </div>
  );
};
export default SecondStep;
