import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Header from './Components/header';
import Home from './Screens/Home';
import LoanForm from './Screens/loanForm';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'monospace montserrat ',
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
      {/* <Header /> */}
      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/form" element={<LoanForm />} />
      </Routes>


    </Router>
    </ThemeProvider>
  );
}

export default App;
