import React, { useEffect, useState } from "react";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import LastStep from "./LastStep";
import MultiStepProgressBar from "./MultiStepProgressBar";
import FourthStep from "./FourthStep";
import { useNavigate } from 'react-router-dom';

const UserForm = (props) => {


  const history = useNavigate();

  //For manageing state of multi steps Form
  const [page, setPage] = useState(props.initialStep || 0);

  const [userInput, setUserInput] = useState({
    propertyAddress: "",
    checkboxValue: false,
    streetNumber: "",
    streetName: "",
    appartmentNumber: "",
    citySubrub: "",
    state: "",
    zipCode: "",
    firstName: "",
    lastName: "",
    companyName: "",
    email: "",
    phone: "",
    education: "",
    creditScore: 650,
    bankruptcy: "no",
    criminalInvestigation: "no",
    transcationType: "New Purchase",
    propertyUse: "Residential",
    propertyType: "Single Family",
    numberOfUnits: 2,
    purchasePrice: "",
    asIsVale: "",
    rehabBudget: "",
    loanAmountRequested: "",
    afterRepairValue: ""
  });

  //proceed to next step
  const nextStep = () => {
    setPage((currPage) => currPage + 1);
    if (page == 3) {
      postAPI();
    }
  };
  const prevStep = () => {
    if(page>1){
      setPage((currPage) => currPage - 1);
    }else{
      history('/');
    }
  }
  const postAPI = () => {
    fetch(process.env.REACT_APP_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userInput)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success:', data);
      })
      .catch((error) => {
        console.log("Error", error)
      })
  }

  const pageTitles = [
    "Welcome! ",
    "Property",
    "Borrower",
    "Property",
  ];
  const pageTitlesSecond = [
    "Welcome! ",
    "Address",
    "Info",
    "Information",
  ];

  const PageDisplay = () => {
    if (page === 0)
      return <FirstStep />;
    else if (page === 1)
      return <SecondStep nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} value={userInput} handleCheckbox={handleCheckbox} handleLocation={handleLocation}/>;
    else if (page === 2)
      return <ThirdStep nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} value={userInput} />;
    else if (page === 3)
      return <FourthStep nextStep={nextStep} prevStep={prevStep} handleChange={handleChange} value={userInput} handleClick={handleClick} />;
    else return <LastStep handleChange={handleChange} value={userInput} />;
  };

  //handle field changes
  const handleChange = (input) => (e) => {
    if(input === 'state'){
      setUserInput({ ...userInput, [input]: e.target.value,['propertyAddress']:`${userInput.streetNumber} ${userInput.streetName} ${userInput.appartmentNumber}, ${userInput.citySubrub}, ${e.target.value} ${userInput.zipCode}` });
    }else if(input === 'streetNumber'){
      setUserInput({ ...userInput, [input]: e.target.value,['propertyAddress']:`${e.target.value} ${userInput.streetName} ${userInput.appartmentNumber}, ${userInput.citySubrub}, ${userInput.state} ${userInput.zipCode}` });
    }else if(input === 'streetName'){
      setUserInput({ ...userInput, [input]: e.target.value,['propertyAddress']:`${userInput.streetNumber} ${e.target.value} ${userInput.appartmentNumber}, ${userInput.citySubrub}, ${userInput.state} ${userInput.zipCode}` });
    }else if(input === 'citySubrub'){
      setUserInput({ ...userInput, [input]: e.target.value,['propertyAddress']:`${userInput.streetNumber} ${userInput.streetName} ${userInput.appartmentNumber}, ${e.target.value}, ${userInput.state} ${userInput.zipCode}` });
    }else if(input === 'zipCode'){
      setUserInput({ ...userInput, [input]: e.target.value,['propertyAddress']:`${userInput.streetNumber} ${userInput.streetName} ${userInput.appartmentNumber}, ${userInput.citySubrub}, ${userInput.state} ${e.target.value}` });
    }else if(input === 'appartmentNumber'){
      setUserInput({ ...userInput, [input]: e.target.value,['propertyAddress']:`${userInput.streetNumber} ${userInput.streetName}${e.target.value === '' ? '' : " Apt "+ e.target.value}, ${userInput.citySubrub}, ${userInput.state} ${userInput.zipCode}` });
    }
    else{
      setUserInput({ ...userInput, [input]: e.target.value });
    }
  };
  const handleCheckbox = (input, value) => {
    console.log('here for checkbox with value',input,value)
    if(input === "checkboxValue" && value){
      setUserInput({
        ...userInput,
        propertyAddress: "",
        streetNumber: "",
        streetName: "",
        appartmentNumber: "",
        citySubrub: "",
        state: "",
        zipCode: "",
        checkboxValue: value
      });
    }else{
      setUserInput({ ...userInput, [input]: value });
    }
  }

  const handleLocation = (value) => {
    setUserInput({ ...userInput, ["citySubrub"]: value.city, ["state"]: value.state, ["zipCode"]: value.zip,['streetName']:value.streetName, ["streetNumber"]: value.streetNumber,['propertyAddress']:value.plain(),checkboxValue:value.checkboxValue });
  }

  const handleClick = (value) => {
    setUserInput({ ...userInput, ["numberOfUnits"]: value })
  }

  return (
    <div className="userForm">
      <div style={{ margin: "auto", width: "58%", marginBottom: "80px" }}>
        <MultiStepProgressBar step={page} />
      </div>

      <div className={page < 4 ? "userForm-container" : "userForm-container-last-step"}>
        {page < 4 && <div className="userForm-container-header addclassforuserform" style={{display: "flex", flexDirection: "row", marginBottom: "20px"}}>
          <h1 style={{marginRight: "8px", color:"#0F1049"}}>
            {pageTitles[page]}
          </h1>
          <h1 style={{fontWeight: "400", color:"#0F1049"}}>
            {pageTitlesSecond[page]}
          </h1>

        </div>}
        <div className="userForm-container-body">{PageDisplay()}</div>
      </div>
    </div>
  );
};
export default UserForm;
